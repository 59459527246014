export const columnsState = [
  { field: "color", headerName: "Color", align: "center" },
  { field: "id", headerName: "Id", align: "right" },
  { field: "nombre", headerName: "Nombre" },
  { field: "elige_empresa", headerName: "Elige empresa", align: "center" },
  { field: "estado_final", headerName: "Estado final", align: "center" },
  { field: "ver", headerName: "Ver" },
];

export const columnsStateEmpresas = [
  { field: "id", headerName: "Id", align: "right" },
  { field: "nombre", headerName: "Nombre" },
  { field: "vuelve_a_comuna", headerName: "Vuelve a la comuna", align: "center" },
  { field: "ver", headerName: "Ver" },
];

export const columnsGeneral = [
  { field: "id", headerName: "Id", align: "right" },
  { field: "nombre", headerName: "Nombre" },
  { field: "ver", headerName: "Ver" },
];

export const columnsAdmin = [
  { field: "nombre", headerName: "Nombre" },
  { field: "apellido", headerName: "Apellido" },
  { field: "email", headerName: "Email" },
  { field: "ver", headerName: "Ver" },
];

export const columnsEmpresa = [
  { field: "nombre", headerName: "Nombre" },
  { field: "email", headerName: "Email" },
  { field: "telefono", headerName: "Telefono" },
  { field: "ver", headerName: "Ver" },
];

export const columnsSucesos2 = [
  { field: "carga_fechahora", headerName: "FECHA LLAMADO", align: "center" },
  { field: "domicilio", headerName: "DOMICILIO", width: 10, align: "left" },
  { field: "subtipo", headerName: "SUBTIPO", align: "center" },
  { field: "comuna", headerName: "COMUNA", align: "left" },
  { field: "tipoDanio", headerName: "DAÑO", width: 60, align: "left" },
  { field: "tipoIncidencia", headerName: "INCIDENCIA", align: "center" },
  { field: "estado", headerName: "ESTADO", align: "center" },
  { field: "corte_de_calle", headerName: "CORTE DE CALLE", align: "center" },
  { field: "estado_empresa", headerName: "ESTADO EMPRESA", align: "center" },
  { field: "ver", headerName: "VER", align: "left" },
];

export const columnsUsers = [
  { field: "nombre", headerName: "Nombre" },
  { field: "apellido", headerName: "Apellido" },
  { field: "comuna", headerName: "Comuna" },
  { field: "email", headerName: "Email" },
  { field: "ver", headerName: "Ver" },
];
