import { TipoEspecieArbolEdit } from "../components/organism";
import {
  AdminEditScreen,
  AdminFichaScreen,
  AdminNewScreen,
  AdminScreen,
  CorteDeCallesEditScreen,
  CorteDeCallesEmpresasEditScreen,
  CorteDeCallesEmpresasFichaScreen,
  CorteDeCallesEmpresasNewScreen,
  CorteDeCallesEmpresasScreen,
  CorteDeCallesFichaScreen,
  CorteDeCallesNewScreen,
  CorteDeCallesScreen,
  EmpresaEditScreen,
  EmpresaFichaScreen,
  EmpresaNewScreen,
  EmpresaScreen,
  EstadoEditScreen,
  EstadoFichaScreen,
  EstadosEmperesaEditScreen,
  EstadosEmperesaFichaScreen,
  EstadosEmperesaNewScreen,
  EstadosEmperesaScreen,
  EstadosNewScreen,
  EstadosScreen,
  HomeScreen,
  IncideciasNewScreen,
  IncidenciasEditScreen,
  IncidenciasEmpresasEditScreen,
  IncidenciasEmpresasFichaScreen,
  IncidenciasEmpresasNewScreen,
  IncidenciasEmpresasScreen,
  IncidenciasFichaScreen,
  IncidenciasScreen,
  MiPerfilScreen,
  SucesoEditScreen,
  SucesoFichaScreen,
  SucesoNewScreen,
  SucesoScreen,
  TipoDanioEditScreen,
  TipoDanioFichaScreen,
  TipoDanioNewScreen,
  TipoDanioScreen,
  TipoEspeciesArbolScreen,
  TipoNombreCalleEditScreen,
  TipoNombreCalleFichaScreen,
  TipoNombreCalleNewScreen,
  TipoNombreCalleScreen,
  UserEditScreen,
  UserFichaScreen,
  UserNewScreen,
  UserScreen,
} from "../pages";
import TipoEspecieArbolEditScreen from "../pages/tipoEspecieArbol/TipoEspecieArbolEditScreen";
import TipoEspecieArbolFichaScreen from "../pages/tipoEspecieArbol/TipoEspecieArbolFichaScreen";
import TipoEspecieArbolNewScreen from "../pages/tipoEspecieArbol/TipoEspecieArbolNewScreen";
import { PRIVATE_ROUTES } from "./name-path.routes";

export const ROUTES_PRIVATES = [
  { path: PRIVATE_ROUTES.HOME, component: HomeScreen },
  //Admin
  { path: PRIVATE_ROUTES.LIST_ADMIN, component: AdminScreen },
  { path: PRIVATE_ROUTES.NEW_ADMIN, component: AdminNewScreen },
  { path: PRIVATE_ROUTES.EDIT_ADMIN, component: AdminEditScreen },
  { path: PRIVATE_ROUTES.FICHA_ADMIN, component: AdminFichaScreen },
  //Users
  { path: PRIVATE_ROUTES.LIST_USERS, component: UserScreen },
  { path: PRIVATE_ROUTES.NEW_USERS, component: UserNewScreen },
  { path: PRIVATE_ROUTES.EDIT_USER, component: UserEditScreen },
  { path: PRIVATE_ROUTES.FICHA_USER, component: UserFichaScreen },
  //States
  { path: PRIVATE_ROUTES.LIST_STATES, component: EstadosScreen },
  { path: PRIVATE_ROUTES.NEW_STATES, component: EstadosNewScreen },
  { path: PRIVATE_ROUTES.EDIT_STATES, component: EstadoEditScreen },
  { path: PRIVATE_ROUTES.FICHA_STATE, component: EstadoFichaScreen },
  //States empresas
  { path: PRIVATE_ROUTES.LIST_STATES_EMPRESAS, component: EstadosEmperesaScreen },
  { path: PRIVATE_ROUTES.NEW_STATES_EMPRESAS, component: EstadosEmperesaNewScreen },
  { path: PRIVATE_ROUTES.EDIT_STATES_EMPRESAS, component: EstadosEmperesaEditScreen },
  { path: PRIVATE_ROUTES.FICHA_STATE_EMPRESAS, component: EstadosEmperesaFichaScreen },
  //Incidencias
  { path: PRIVATE_ROUTES.LIST_INCIDENT, component: IncidenciasScreen },
  { path: PRIVATE_ROUTES.NEW_INCIDENT, component: IncideciasNewScreen },
  { path: PRIVATE_ROUTES.EDIT_INCIDENT, component: IncidenciasEditScreen },
  { path: PRIVATE_ROUTES.FICHA_INCIDENT, component: IncidenciasFichaScreen },
  //Incidencias empresas
  { path: PRIVATE_ROUTES.LIST_INCIDENT_EMPRESAS, component: IncidenciasEmpresasScreen },
  { path: PRIVATE_ROUTES.NEW_INCIDENT_EMPRESAS, component: IncidenciasEmpresasNewScreen },
  { path: PRIVATE_ROUTES.EDIT_INCIDENT_EMPRESAS, component: IncidenciasEmpresasEditScreen },
  { path: PRIVATE_ROUTES.FICHA_INCIDENT_EMPRESAS, component: IncidenciasEmpresasFichaScreen },
  //Cortes
  { path: PRIVATE_ROUTES.LIST_CORTE_DE_CALLE, component: CorteDeCallesScreen },
  { path: PRIVATE_ROUTES.NEW_CORTE_DE_CALLE, component: CorteDeCallesNewScreen },
  { path: PRIVATE_ROUTES.EDIT_CORTE_DE_CALLE, component: CorteDeCallesEditScreen },
  { path: PRIVATE_ROUTES.FICHA_CORTE_DE_CALLE, component: CorteDeCallesFichaScreen },
  //Cortes empresas
  { path: PRIVATE_ROUTES.LIST_CORTE_DE_CALLE_EMPRESAS, component: CorteDeCallesEmpresasScreen },
  { path: PRIVATE_ROUTES.NEW_CORTE_DE_CALLE_EMPRESAS, component: CorteDeCallesEmpresasNewScreen },
  { path: PRIVATE_ROUTES.EDIT_CORTE_DE_CALLE_EMPRESAS, component: CorteDeCallesEmpresasEditScreen },
  { path: PRIVATE_ROUTES.FICHA_CORTE_DE_CALLE_EMPRESAS, component: CorteDeCallesEmpresasFichaScreen },
  //Sucesos
  { path: PRIVATE_ROUTES.LIST_SUCESOS, component: SucesoScreen },
  { path: PRIVATE_ROUTES.NEW_SUCESOS, component: SucesoNewScreen },
  { path: PRIVATE_ROUTES.EDIT_SUCESOS, component: SucesoEditScreen },
  { path: PRIVATE_ROUTES.FICHA_SUCESOS, component: SucesoFichaScreen },
  //Tipo daño
  { path: PRIVATE_ROUTES.LIST_TIPO_DAÑO_EMPRESAS, component: TipoDanioScreen },
  { path: PRIVATE_ROUTES.NEW_TIPO_DAÑO_EMPRESAS, component: TipoDanioNewScreen },
  { path: PRIVATE_ROUTES.EDIT_TIPO_DAÑO_EMPRESAS, component: TipoDanioEditScreen },
  { path: PRIVATE_ROUTES.FICHA_TIPO_DAÑO_EMPRESAS, component: TipoDanioFichaScreen },
  //Tipo nombre calle
  { path: PRIVATE_ROUTES.LIST_TIPO_NOMBRE, component: TipoNombreCalleScreen },
  { path: PRIVATE_ROUTES.NEW_TIPO_NOMBRE, component: TipoNombreCalleNewScreen },
  { path: PRIVATE_ROUTES.EDIT_TIPO_NOMBRE, component: TipoNombreCalleEditScreen },
  { path: PRIVATE_ROUTES.FICHA_TIPO_NOMBRE, component: TipoNombreCalleFichaScreen },
  //Tipo especie arbol
  { path: PRIVATE_ROUTES.LIST_ESPECIE_ARBOL, component: TipoEspeciesArbolScreen },
  { path: PRIVATE_ROUTES.NEW_ESPECIE_ARBOL, component: TipoEspecieArbolNewScreen },
  { path: PRIVATE_ROUTES.EDIT_ESPECIE_ARBOL, component: TipoEspecieArbolEditScreen },
  { path: PRIVATE_ROUTES.FICHA_ESPECIE_ARBOL, component: TipoEspecieArbolFichaScreen },
  //Empresa
  { path: PRIVATE_ROUTES.LIST_EMPRESA, component: EmpresaScreen },
  { path: PRIVATE_ROUTES.FICHA_EMPRESA, component: EmpresaFichaScreen },
  { path: PRIVATE_ROUTES.EDIT_EMPRESA, component: EmpresaEditScreen },
  { path: PRIVATE_ROUTES.NEW_EMPRESA, component: EmpresaNewScreen },
  //Mi perfil
  { path: PRIVATE_ROUTES.SELF, component: MiPerfilScreen },
];
