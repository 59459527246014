import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import NaturePeopleIcon from "@mui/icons-material/NaturePeople";
import PersonIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
import { PRIVATE_ROUTES } from "../routes/name-path.routes";

export const SidebarData = [
  {
    id: 1,
    label: "Administradores",
    icono: (
      <AdminPanelSettingsIcon
        sx={{
          color: "black",
        }}
      />
    ),
    path: PRIVATE_ROUTES.LIST_ADMIN,
    desplegable: false,
  },
  {
    id: 2,
    label: "Usuarios Comunas",
    icono: (
      <PersonIcon
        sx={{
          color: "black",
        }}
      />
    ),
    path: PRIVATE_ROUTES.LIST_USERS,
    desplegable: false,
  },
  {
    id: 3,
    label: "Usuarios Empresas",
    icono: (
      <PersonIcon
        sx={{
          color: "black",
        }}
      />
    ),
    path: PRIVATE_ROUTES.LIST_EMPRESA,
    desplegable: false,
  },
  {
    id: 4,
    label: "Configuracion",
    icono: (
      <SettingsIcon
        sx={{
          color: "black",
        }}
      />
    ),
    children: [
      { to: PRIVATE_ROUTES.LIST_STATES, label: "Estados comunas" },
      { to: PRIVATE_ROUTES.LIST_STATES_EMPRESAS, label: "Estados empresa" },
      { to: PRIVATE_ROUTES.LIST_INCIDENT, label: "Tipos de incidencias comunas" },
      { to: PRIVATE_ROUTES.LIST_INCIDENT_EMPRESAS, label: "Tipos de incidencias Empresa" },
      { to: PRIVATE_ROUTES.LIST_CORTE_DE_CALLE, label: "Tipos de corte de calles comunas" },
      { to: PRIVATE_ROUTES.LIST_CORTE_DE_CALLE_EMPRESAS, label: "Tipos de corte de calles empresas" },
      { to: PRIVATE_ROUTES.LIST_TIPO_DAÑO_EMPRESAS, label: "Tipos de daños" },
      { to: PRIVATE_ROUTES.LIST_TIPO_NOMBRE, label: "Nombre calles" },
      { to: PRIVATE_ROUTES.LIST_ESPECIE_ARBOL, label: "Especie arbóreas" },
    ],
    desplegable: true,
  },
  {
    id: 5,
    label: "Sucesos",
    icono: (
      <NaturePeopleIcon
        sx={{
          color: "black",
        }}
      />
    ),
    path: PRIVATE_ROUTES.LIST_SUCESOS,
    desplegable: false,
  },
];
